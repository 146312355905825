﻿
export default function () {
  const axios = require("axios");

  var mapLoaded = false;
  function aboutMusl() {
    const el = document.querySelector("#about-musl-button");
    el.addEventListener("click",
      e => {
        if (!mapLoaded) {
          simplemaps_usmap.load();
          mapLoaded = true;
        }
      });
  };

  function news() {
    const el = document.querySelector("#news-button");
    el.addEventListener("click",
      e => {
        axios.get("/latest-news", { params: { ps: 10 } , headers: { 'x-requested-with': "XMLHttpRequest" } })
          .then(function (response) {
            document.querySelector("#news-result").innerHTML = response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
      });
  };

  aboutMusl();
  news();
}
