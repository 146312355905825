﻿export default function() {

  const axios = require("axios");

  function changeGameCode() {
    const el = document.querySelector("#GameCode");
    el.addEventListener("change",
      e => {
        e.preventDefault();
        const url = new URL(window.location);
        const gc = el.value;
        url.searchParams.set("gc", gc);
        window.location = url;
      });
  };

  function clickSearch() {
    const el = document.querySelector("#searchNumbers");
    el.addEventListener("click",
      e => {
        e.preventDefault();
        clearForm(false);
        const errR = document.querySelector("#error-range");
        const errU = document.querySelector("#error-unique");

        const gc = document.querySelector("#GameCode").value;
        const dy = document.querySelector("#Days").value;
        var args = { gc: gc, days: dy, n:[], b:[] };
        var hasErr = false;

        var p = "n";
        document.querySelectorAll("[id*='number_']").forEach(el => {
          var val = el.value;
          if (isNaN(parseInt(val)) || parseInt(val) < parseInt(el.min) || parseInt(val) > parseInt(el.max)) {
            errR.classList.remove("d-none");
            hasErr = true;
          }
          if (el.id.startsWith("number_2") && p !== "b") {
            p = "b";
          }
          args[p].push(val);
        });

        if (hasErr) return;

        if ((new Set(args["n"])).size !== args["n"].length || (new Set(args["b"])).size !== args["b"].length) {
          errU.classList.remove("d-none");
          hasErr = true;
        }

        if (hasErr) return;

        const url = new URL(window.location);
        axios.post(url, args, { headers: { 'X-Requested-With': "XMLHttpRequest" } })
          .then(function (response) {
            document.querySelector("#searchNumbersResults").innerHTML = response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
      });
  };

  function clickClear() {
    const el = document.querySelector("#clearNumbers");
    el.addEventListener("click",
      e => {
        e.preventDefault();
        clearForm(true);
      });
  };

  function clearForm(clearNumbers) {
    const errR = document.querySelector("#error-range");
    const errU = document.querySelector("#error-unique");
    errR.classList.add("d-none");
    errU.classList.add("d-none");
    document.querySelector("#searchNumbersResults").innerHTML = "";

    if (clearNumbers) {
      document.querySelectorAll("[id*='number_']").forEach(el => {
        el.value = "";
      });
    }
  };

  changeGameCode();
  clickSearch();
  clickClear();
};