import { Collapse, Modal } from "bootstrap";

export default function () {
    function alert() {
        const alBtn = document.body.querySelector("#close-alert");

        if (!alBtn) return;

        const cookie = alBtn.dataset.cookie ?? "pb";

        if (!decodeURIComponent(document.cookie).includes(cookie + "=")) {
            const alertEl = alBtn.closest(".alert");
            alertEl.classList.remove("d-none");

            alBtn.addEventListener("click", (e) => {
                const d = new Date();
                const days = 7;
                d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
                let expires = "expires=" + d.toUTCString();
                document.cookie = cookie + "=true;" + expires + ";path=/";
            });
        }
    }
    function consent() {
        const cookConsent = document.body.querySelector("#cookie-consent");

        const alBtn = document.body.querySelector(
            "#cookie-consent button[data-consent-btn]"
        );

        if (!alBtn) return;

        var modal = Modal.getOrCreateInstance(cookConsent);

        const cookie = ".AspNet.Consent";

        if (!decodeURIComponent(document.cookie).includes(cookie + "=")) {
            if (
                !(
                    pageJson.path === "/privacy-policy" ||
                    pageJson.path === "/es/politica-de-privacidad"
                )
            ) {
                modal.show();
            }
            cookConsent.classList.remove("d-none");

            alBtn.addEventListener("click", (e) => {
                const d = new Date();
                const days = 365;
                d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
                let expires = "expires=" + d.toUTCString();
                document.cookie =
                    cookie + "=yes;" + expires + ";path=/;secure;samesite=none";

                modal.hide();
            });
        }
    }

    alert();
    consent();
}
