
import common from "./common.js";
import game from "./game.js";
import winnersgallery from "./winnersgallery.js";
import checknumbers from "./checknumbers.js";
import previousdraw from "./previousdraw.js";
import drawresult from "./drawresult.js";
import media from "./media.js";
import unsubscribe from "./unsubscribe.js";

const functions = { common, game, winnersgallery, checknumbers, previousdraw, drawresult, media, unsubscribe };


window.addEventListener("DOMContentLoaded",
  event => {
    document.body.dataset.scripts.split(" ").forEach(script => {
      if (typeof (functions[script]) == "function") {
        functions[script]();
      }
    });
  });