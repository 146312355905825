
export default function () {
  const axios = require("axios");

  var drawDate;
  var timer;

  var modules = {
    numbers: {
      name: "numbers",
      cardEl: document.querySelector("#numbers"),
      hash: pageJson.hashInfo.nh
    },
    nextdraw: {
      name: "next-drawing",
      cardEl: document.querySelector("#next-drawing"),
      hash: pageJson.hashInfo.dh
    },
    winners: {
      name: "winners",
      cardEl: document.querySelector("#winners"),
      hash: pageJson.hashInfo.wh
    },
  };

  function fade(element) {
    return new Promise(function (resolve) {
      var op = 1;
      var ft = setInterval(function () {
        if (op <= 0.1) {
          clearInterval(ft);
          resolve();
        }
        element.style.opacity = op;
        element.style.filter = `alpha(opacity=${op * 100})`;
        op -= op * 0.1;
      },
        20);
    });
  }

  function unfade(element) {
    return new Promise(function (resolve) {
      var op = 0.1;
      element.style.display = "block";
      var ut = setInterval(function () {
        if (op >= 1) {
          clearInterval(ut);
          resolve();
        }
        element.style.opacity = op;
        element.style.filter = `alpha(opacity=${op * 100})`;
        op += op * 0.1;
      },
        20);
    });
  }

  function flipCard(el, val) {
    var newVal = val.toString().padStart(2, '0');
    var current = el.querySelector("b.flip-card__bottom").dataset["value"];

    if (newVal !== current) {
      el.querySelector("b.flip-card__top").textContent = newVal;
      el.querySelector("b.flip-card__bottom").dataset["value"] = newVal;
      el.querySelector("b.flip-card__back").dataset["value"] = current;
      el.querySelector("b.flip-card__back-bottom").dataset["value"] = current;

      el.classList.remove("flip");
      void el.offsetWidth
      el.classList.add("flip");
    }
  }

  function countDown(elp) {
    let sec = Math.floor((drawDate - new Date()) * .001);
    if (sec < 0) {
      clearInterval(timer);
      elp.classList.add("d-none");
      modules.nextdraw.cardEl.querySelector("#results-pending").classList.remove("d-none");
      timer = setInterval(checkResults, 10000);
    } else {

      let hrs = Math.floor(sec / 3600);
      let mns = Math.floor(sec % 3600 / 60);
      let scs = Math.floor(sec % 60);

      const elH = elp.querySelector("#hours");
      const elM = elp.querySelector("#minutes");
      const elS = elp.querySelector("#seconds");

      if (hrs > 0) {
        elH.classList.remove("d-none");
      }
      if (hrs > 0 || mns > 0 || scs > 0) {
        elM.classList.remove("d-none");
        elS.classList.remove("d-none");
      }

      flipCard(elH, hrs);
      flipCard(elM, mns);
      flipCard(elS, scs);
    }
  }

  function checkResults() {
    var r = [];

    if (modules.numbers.cardEl && modules.numbers.cardEl.firstElementChild.classList.contains("complete")) {
      modules.numbers.cardEl.querySelector("svg.spinner").classList.remove("d-none");
    } else {
      modules.nextdraw.cardEl.querySelector("svg.spinner").classList.remove("d-none");
      modules.winners.cardEl.querySelector("svg.spinner").classList.remove("d-none");
    }

    axios.get(`/v1/gameapi/${pageJson.gameCode}`)
      .then(async(response) => {
        if (modules.numbers.cardEl && response.data.nh !== modules.numbers.hash) {
          await refreshPanel(modules.numbers);
          modules.numbers.hash = response.data.nh;
        }
        if (response.data.wh !== modules.winners.hash) {
          await refreshPanel(modules.winners);
          modules.winners.hash = response.data.wh;
        }
        if (response.data.dh !== modules.nextdraw.hash) {
          await refreshPanel(modules.nextdraw);
          modules.nextdraw.hash = response.data.dh;
          return true;
        }
        return false;
      })
      .then((roll) => {
        if (roll === true) {
          console.log('here');
          clearInterval(timer);
          setTimer();
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setTimeout(function () {
          if(modules.numbers.cardEl){
            modules.numbers.cardEl.querySelector("svg.spinner").classList.add("d-none");
          }
          modules.nextdraw.cardEl.querySelector("svg.spinner").classList.add("d-none");
          modules.winners.cardEl.querySelector("svg.spinner").classList.add("d-none");
        }, 1000);
      });
  }

  function refreshPanel(module) {
    return new Promise(function (resolve, reject) {
      axios.get(`/v1/gameapi/${module.name}`,
        {
          params: {
            gamecode: pageJson.gameCode,
            language: pageJson.languageCode
          }
        })
        .then((response) => {
          fade(module.cardEl)
            .then(() => {
              module.cardEl.innerHTML = response.data;
              unfade(module.cardEl);
              resolve(response);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  }

  function setTimer() {
    const elCd = modules.nextdraw.cardEl.querySelector("#nextDraw");
    const dd = elCd.dataset["drawdateutc"];
    if(dd.length > 0){
      drawDate = new Date(dd);
    }
    if (Math.floor((drawDate - new Date()) * .001)) {
      timer = setInterval(countDown, 1000, elCd);
    } else {
      timer = setInterval(checkResults, 10000);
    }
  }

  var mapLoaded = false;
  function clickWhereToPlay() {
    const el = document.querySelector("#whereToPlayButton");
    el.addEventListener("click",
      e => {
        if (!mapLoaded) {
          simplemaps_usmap.load();
          mapLoaded = true;
        }
      });
  };

  function clickSubmitSignUp() {
    const form = document.querySelector("#submitSignUpForm");
    if (form === null) return;

    var formData = {
      language: pageJson.languageCode
    };


    form.addEventListener('submit', async event => {
      event.preventDefault();
      formData.gameCode = pageJson.gameCode;
      formData.email = form.querySelector("#Email").value;

      var response = grecaptcha.getResponse();

      if (response.length > 0) {
        formData.Request = response;
        axios.post('/v1/subscriptionapi/subscribe', formData)
          .then(function (response) {
            form.querySelector("#alertbox").innerHTML = response.data;
            form.querySelectorAll(".alert").forEach(function (el) {
              setTimeout(() => {
                el.classList.add('d-none');
              }, 20000);
            });
          })
          .catch(function (error) {
            form.querySelector("#alertbox").innerHTML = error.response.data;
          });
      }
    });
  };

  setTimer();
  clickWhereToPlay();
  clickSubmitSignUp();
}
