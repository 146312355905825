﻿const config = {
  "powerball": {
    "daysOfWeek": [1, 3, 6],
    "minDate": "1992-04-22"
  },
  "2by2": {
    "daysOfWeek": [0, 1, 2, 3, 4, 5, 6],
    "minDate": "2002-06-01"
  },
  "lotto-america": {
    "daysOfWeek": [1, 3, 6],
    "minDate": "2017-11-15"
  },
  "pb-double-play": {
    "daysOfWeek": [1, 3, 6],
    "minDate": "2021-08-23"
  }
};

import flatpickr from "flatpickr";

export function calendar(el, onChange) {

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  var lang = null;
  if (pageJson.languageCode === "es") {
    lang = require("flatpickr/dist/l10n/es.js").default.es;
    lang.firstDayOfWeek = 0;
  }

  let c = config[el.dataset.game];

  flatpickr(el, {
    disable: [
      function (date) {
        return c.daysOfWeek.indexOf(date.getDay()) === -1;
      }
    ],
    dateFormat: "Y-m-d",
    altFormat: "m/d/Y",
    altInput: true,
    altInputClass: "form-control col calendar",
    minDate: c.minDate,
    maxDate: "today",
    locale: lang,
    onChange: function (selectedDates, dateStr, instance) {
      let date = selectedDates[0];
      var dt = [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-')

      onChange(dt);
    }
  });
};